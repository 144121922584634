import { FC, useRef, useState } from "react";
import { NotificationsApi, NotificationStatus } from "@practice/sdk";
import classNames from "classnames";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import { useSDKApi } from "@hooks/use-sdk-api";
import { useOutsideClick } from "@lib/utils/use-outside-click";

import AlarmOnIcon from "@components/Icons/AlarmOnIcon";
import NotificationPanelList from "@components/NotificationPanel/NotificationPanelList";
import Transition from "@components/Transition";

interface NotificationPanelTriggerInnerProps {
  hasUnreadNotifications: boolean;
  onClick: () => void;
  className?: string;
}

const styles = {
  inactive:
    "text-grey-800 bg-grey-950 hover:bg-white active:bg-grey-950 border border-grey-900",
  unread:
    "text-white bg-peach-600 border border-peach-600 hover:bg-peach-500 hover:border-peach-500 active:bg-peach-600 active:border-peach-600",
};

export const NotificationPanelTriggerInner: FC<
  NotificationPanelTriggerInnerProps
> = ({ hasUnreadNotifications, onClick, className }) => (
  <button
    className={classNames(
      "p-1 rounded-lg",
      styles[hasUnreadNotifications ? "unread" : "inactive"],
      className
    )}
    onClick={onClick}
  >
    <AlarmOnIcon />
  </button>
);

interface NotificationPanelTriggerProps {
  className?: string;
  shouldShrink?: boolean;
}

const useGetUnreadNotifications = ({ aid }: { aid: string }) => {
  const notificationApi = useSDKApi(NotificationsApi);

  return useSWR(
    aid ? `/${aid}/notifications-unread` : undefined,
    async () => {
      if (!aid) return;
      return notificationApi.listNotifications({
        limit: 10,
        accountId: aid,
        status: NotificationStatus.Unread,
      });
    },
    { dedupingInterval: 120000 }
  );
};

const NotificationPanelTrigger: FC<NotificationPanelTriggerProps> = ({
  className,
  shouldShrink = false,
}) => {
  const panelRef = useRef<HTMLDivElement>(null);

  const [showNotificationPanel, setShowNotificationPanel] = useState(false);

  const { aid } = useAuth();
  if (!aid) {
    throw Error("Missing AccountId in NotificationPanelTrigger");
  }

  const { data: response, mutate } = useGetUnreadNotifications({ aid });
  const hasUnreadNotifications = (response?.count || 0) > 0;

  useOutsideClick(panelRef?.current, () => setShowNotificationPanel(false));

  return (
    <div ref={panelRef} className="relative overflow-visible">
      <NotificationPanelTriggerInner
        className={className}
        hasUnreadNotifications={hasUnreadNotifications}
        onClick={() => setShowNotificationPanel(!showNotificationPanel)}
      />
      <Transition
        show={showNotificationPanel}
        className={classNames(
          "absolute z-50 cursor-auto",
          shouldShrink ? "translate-x-4" : "lg:translate-x-full -translate-x-64"
        )}
      >
        <NotificationPanelList
          onUpdate={mutate}
          hasUnreadActivity={hasUnreadNotifications}
        />
      </Transition>
    </div>
  );
};

export default NotificationPanelTrigger;
