import { FC, useRef } from "react";
import classNames from "classnames";
import Link from "next/link";

import { useAuth } from "@contexts/auth";
import { useOrganizationAccounts } from "@hooks/use-organization-accounts";
import { useOutsideClick } from "@lib/utils/use-outside-click";

import { ConditionalAccessTypeRenderer } from "@components/ConditionalTeamsRenderer";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import GearIcon from "@components/Icons/GearIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import LogoutIcon from "@components/Icons/LogoutIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import Transition from "@components/Transition";

interface SidebarProfileMenuInnerProps {
  organizationSlug: string | undefined;
  handleClickSignOut: () => void;
  hasFullAccess: boolean;
  memberSlug?: string;
  onWorkspaceSwitch?: () => void;
}

const linkClassName =
  "flex justify-between items-center p-4 cursor-pointer hover:bg-grey-950";

export const SidebarProfileMenuInner: FC<SidebarProfileMenuInnerProps> = ({
  organizationSlug,
  handleClickSignOut,
  hasFullAccess,
  memberSlug,
  onWorkspaceSwitch,
}) => (
  <div
    className="w-52 mx-auto rounded-md shadow-lg overflow-hidden bg-white ring-1 ring-black/5"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="options-menu"
  >
    {organizationSlug && (
      <Link
        href={`/me/${organizationSlug}${memberSlug ? `/${memberSlug}` : ""}`}
        target="_blank"
        className={linkClassName}
      >
        {hasFullAccess ? "Team" : "Public"} profile
        <SocialWebIcon className="w-6 h-6" />
      </Link>
    )}
    <Link href="/settings" className={linkClassName}>
      Settings
      <GearIcon />
    </Link>
    <ConditionalAccessTypeRenderer>
      <Link href="/manage-plan" className={linkClassName}>
        Manage plan
        <CreditCardIcon />
      </Link>
    </ConditionalAccessTypeRenderer>
    <ConditionalAccessTypeRenderer>
      <Link href="/teams" className={linkClassName}>
        Teams
        <GroupIcon />
      </Link>
    </ConditionalAccessTypeRenderer>
    {onWorkspaceSwitch ? (
      <div onClick={onWorkspaceSwitch} className={linkClassName}>
        Switch workspace
        <LoopIcon />
      </div>
    ) : null}
    <div
      onClick={handleClickSignOut}
      className={classNames(
        linkClassName,
        "text-peach-500 border-t border-grey-950"
      )}
    >
      Sign out
      <LogoutIcon />
    </div>
  </div>
);

interface SidebarProfileMenuProps {
  show: boolean;
  setShow: (show: boolean) => void;
  className?: string;
}

const SidebarProfileMenu: FC<SidebarProfileMenuProps> = ({
  show,
  setShow,
  className,
}) => {
  const { organization, signout, aid, onWorkspaceSwitch } = useAuth();
  const {
    hasMoreThanOneMember,
    fullAccessMembers,
    elevatedMembers,
    limitedMembers,
  } = useOrganizationAccounts();

  const hasFullAccess = !!(
    hasMoreThanOneMember && fullAccessMembers?.find((m) => m.id === aid)
  );

  const elevatedMember =
    hasMoreThanOneMember && elevatedMembers?.find((m) => m.id === aid);

  const limitedMember =
    hasMoreThanOneMember && limitedMembers?.find((m) => m.id === aid);

  const memberSlug = elevatedMember
    ? elevatedMember.slug || elevatedMember.id
    : limitedMember
    ? limitedMember.slug || limitedMember.id
    : undefined;

  const menuRef = useRef<HTMLElement>(null);

  useOutsideClick(menuRef?.current, () => setShow(false));

  return (
    <Transition
      ref={menuRef}
      show={show}
      className={classNames("absolute", className)}
    >
      <SidebarProfileMenuInner
        organizationSlug={organization?.slug}
        handleClickSignOut={signout}
        hasFullAccess={hasFullAccess}
        memberSlug={memberSlug}
        onWorkspaceSwitch={onWorkspaceSwitch}
      />
    </Transition>
  );
};

export default SidebarProfileMenu;
